// Color Scheme

$shlk-blue:#21486f;
$shlk-blue-light: #3f6d9b;
$shlk-yellow:#ffa822;

.version{
  color: $shlk-blue;
  font-size: 0.85rem;
}
.copyright{
  color:#73818f !important;
  font-size: 0.8rem;
}
//header
.header-fixed .app-header {
  background: #21486f;
  color: #fff;
}
.app-header{
  border-bottom:0;
}
.app-header .navbar-brand {
  width: 200px;
}

.text-shlk-yellow{
  color:$shlk-yellow
}
.text-shlk-blue{
  color:$shlk-blue
}
.card-header{
  font-weight: 600;
}
// Here you can add other styles
.error-msg {
  color: red;
}
//bootstrap forms
.form-control.ng-dirty.ng-invalid{
  border-color: #f44336;
}

#active-spplier {
  background-color: red;
}

.btn-shlk-yellow{
  background-color: #ffa822
}

.btn-shlk-blue{
  background-color: $shlk-blue;
  color:white;
}
.btn-shlk-blue:hover{
  color:white;
}
.btn-outline-shlk-blue{
  border:1px solid $shlk-blue;
  color:$shlk-blue;
}


.btn-shlk-blue-light{
  background-color: $shlk-blue-light;
  color: #fff;
}
.btn-shlk-blue-light:hover{
  background-color: #426d97;
  color:#fff;
}
.btn-shlk-excel-export{
  background-color: #689F38!important;
  border:1px solid #689F38 !important;
  color:white !important;
}
.btn-shlk-print{
  background-color: #a71a44!important;
  border:1px solid #a71a44 !important;
  color:white !important;
}
.btn-shlk-print:hover{
  background-color: #a53959;
}

.field-form-control{
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
}

.ellipsis-text{
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.text-limit-lines{
  overflow: hidden;
   text-overflow: ellipsis;
   display: -webkit-box;
   -webkit-line-clamp: 2;
   -webkit-box-orient: vertical;
}




.title-wrapper {
  height: 25px !important;
  padding: 0;
  margin-right: 0;

  .alert {
    position: relative;
    padding: .75rem 1.25rem;
    margin-bottom: 1rem;
    border: 1px solid transparent;
    border-radius: 2px;
    position: absolute;
    width: 100%;
    z-index: 1;
    left: 0;
    margin-left: -1px;
  }
}

.clickable {
  cursor: pointer;
}

button.navbar-toggler.d-lg-none:not(:nth-child(1)) {
  display: none;
}

@media (max-width: 991.98px) {
  .app-header .navbar-brand {
      position: absolute;
      left: 20%;
      margin-left: -77.5px;
  }
}
.b-one{
  color: #ffc107;
  background: #21486f;
  border: 1px solid #ffc107 !important;
  padding: 0.5rem 1rem !important;
  border-radius: 0.25rem !important;
}

@media print {
  body * {
    visibility:hidden;
  }
  #printable, #printable * {
    visibility:visible;
  }
  #printable {
    position:absolute;
    left:0;
    top:0;
  }
}