// If you want to override variables do it here
@import "variables";

// Import styles
@import "node_modules/@coreui/coreui/scss/coreui";
@import "ng-pick-datetime/assets/style/picker.min.css";

// If you want to add something do it here
@import "custom";
@import "status";
@import "vendors/prime-ng";
@import "vendors/ngx_bootstrap";
@import "avatar";