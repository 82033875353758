@import '../custom';

$base-font-size : 14px;

.p-component{
    font-size: $base-font-size;
}
// compact table rows
.p-datatable.p-datatable-sm {
  .p-datatable-thead > tr > th {
    white-space: nowrap !important;
  }
  .p-datatable-tbody > tr > td{
    padding: 0.4rem 0.4em !important;
  }
}

.p-button{
  padding: 0.375rem 0.75rem;
  background-color: $shlk-blue-light;
  border: 1px solid $shlk-blue-light;
  color:white;
}
.p-dropdown,
.p-autocomplete,
.p-multiselect{
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
}
.p-selectbutton .p-button.p-highlight {
  background: $shlk-blue;
  border-color: $shlk-blue
}
/* .p-dropdown .p-dropdown-trigger{
  background-color: $shlk-blue-light;
  color:white;
} */
.p-inputtext{
  width: 100%;
  font-size: $base-font-size;
  height: calc(1.5em + 0.75rem + 2px);
}
.p-datepicker table{
  font-size: $base-font-size;
}
.p-datepicker{
  width: 360px!important;
  top: 96px !important;
}
.p-calendar{
  width: 100%;
}
.p-inputnumber {
  width: 15vw!important;
}
.p-chips,
.p-chips-multiple-container{
  width: 100%;
}
.p-chips > ul.p-inputtext{
  height: initial;
}
.p-chips-token{
  background: $shlk-blue-light !important;
  color: #fff !important;
  border-radius: .25rem !important;
  font-size: 13px;
}
.p-chips .p-chips-multiple-container .p-chips-input-token input{
  font-size: $base-font-size;
}
.p-toast{
  opacity: 1;
}
.p-toast-top-right {
  top: 60px !important;
}