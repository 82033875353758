@import "./custom";

/*
* Status badge styles
*/

.status-badge {
  font-size: 0.8em;
  padding: 0.25em 0.5rem;
}
.status-badge-lg {
  font-size: 0.9em !important;
}

// Default

.status-new {
  background-color: $shlk-blue;
  color: white;
}
.status-review {
  background-color: #8e9aaf;
  color: white;
}
.status-cancel,
.status-rejected{
  background-color: #f86c6b;
  color: white;
}
.status-submitted,
.status-assign,
.status-transferred{
  background-color: $shlk-yellow;
  color: $shlk-blue;
}
.status-received,
.status-return,
.status-returned,
.status-completed,
.status-accepted{
  background-color: #4dbd74;
  color: white;
}

.status-partially-received,
.status-partially-completed,
.status-partially-issued
 {
  background-color: #bdbb4d;
  color: white;
}

.status-approved {
  background-color: #20a8d8;
  color: white;
}