@import "../custom";

bs-datepicker-container,
bs-daterangepicker-container {
  z-index: 1200;
}
.bs-datepicker-head {
  background-color: $shlk-blue !important;
}

.bs-datepicker-body table td span.selected {
  background-color: $shlk-blue !important;
}
